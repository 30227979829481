// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.register-success-container {
    max-width: 750px;
    margin: 100px auto;
    padding: 75px;
    background-color: rgba(0, 0, 0, .70);
    border-radius: 5px;
    text-align: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  .register-success-container p{
    color: white;
  }
  
  .register-success-container h2 {
    color: white
  } 
  
  /* p {
    margin-top: 20px;
  } */
  
  /* Additional styling for mobile responsiveness */
  @media (max-width: 600px) {
    .register-success-container {
      max-width: 300px;
      padding: 10px;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Mwelcomeverify/Mwelcomeverify.css"],"names":[],"mappings":";;AAEA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,aAAa;IACb,oCAAoC;IACpC,kBAAkB;IAClB,kBAAkB;IAClB,uCAAuC;EACzC;EACA;IACE,YAAY;EACd;;EAEA;IACE;EACF;;EAEA;;KAEG;;EAEH,iDAAiD;EACjD;IACE;MACE,gBAAgB;MAChB,aAAa;IACf;EACF","sourcesContent":["\n\n.register-success-container {\n    max-width: 750px;\n    margin: 100px auto;\n    padding: 75px;\n    background-color: rgba(0, 0, 0, .70);\n    border-radius: 5px;\n    text-align: center;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n  }\n  .register-success-container p{\n    color: white;\n  }\n  \n  .register-success-container h2 {\n    color: white\n  } \n  \n  /* p {\n    margin-top: 20px;\n  } */\n  \n  /* Additional styling for mobile responsiveness */\n  @media (max-width: 600px) {\n    .register-success-container {\n      max-width: 300px;\n      padding: 10px;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
