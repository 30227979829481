// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ActionsGrid_card__Yihik {
  background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-6));
}

.ActionsGrid_title__f04mB {
  font-family:
    Greycliff CF,
    var(--mantine-font-family);
  font-weight: 700;
}

.ActionsGrid_item__YIlWZ {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: var(--mantine-radius-md);
  height: 90px;
  background-color: light-dark(var(--mantine-color-white), var(--mantine-color-dark-7));
  transition:
    box-shadow 150ms ease,
    transform 100ms ease;

  @mixin hover {
    box-shadow: var(--mantine-shadow-md);
    transform: scale(1.05);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Dashboard/commonComponents/ActionsGrid.module.css"],"names":[],"mappings":"AAAA;EACE,sFAAsF;AACxF;;AAEA;EACE;;8BAE4B;EAC5B,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;EAClB,uCAAuC;EACvC,YAAY;EACZ,qFAAqF;EACrF;;wBAEsB;;EAEtB;IACE,oCAAoC;IACpC,sBAAsB;EACxB;AACF","sourcesContent":[".card {\n  background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-6));\n}\n\n.title {\n  font-family:\n    Greycliff CF,\n    var(--mantine-font-family);\n  font-weight: 700;\n}\n\n.item {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  text-align: center;\n  border-radius: var(--mantine-radius-md);\n  height: 90px;\n  background-color: light-dark(var(--mantine-color-white), var(--mantine-color-dark-7));\n  transition:\n    box-shadow 150ms ease,\n    transform 100ms ease;\n\n  @mixin hover {\n    box-shadow: var(--mantine-shadow-md);\n    transform: scale(1.05);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `ActionsGrid_card__Yihik`,
	"title": `ActionsGrid_title__f04mB`,
	"item": `ActionsGrid_item__YIlWZ`
};
export default ___CSS_LOADER_EXPORT___;
