// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.root {
    padding: calc(var(--mantine-spacing-xl) * 1.5);
  }
  
  .label {
    font-family:
      Greycliff CF,
      var(--mantine-font-family);
  }

  /* .rs-stack-item  {
    background-color: var(--bg-rs-item-card) !important;
    color: var(--text-color);
  } */

  .account-card {
    background-color: var(--bg-account-card) !important;
  }

  .m_1b7284a3 {
    background-color: var(--bg-color) !important;
    color: var(--text-color);
  }`, "",{"version":3,"sources":["webpack://./src/components/Dashboard/commonComponents/StatsGrid/StatisticGridIcon.css"],"names":[],"mappings":"AAAA;IACI,8CAA8C;EAChD;;EAEA;IACE;;gCAE4B;EAC9B;;EAEA;;;KAGG;;EAEH;IACE,mDAAmD;EACrD;;EAEA;IACE,4CAA4C;IAC5C,wBAAwB;EAC1B","sourcesContent":[".root {\n    padding: calc(var(--mantine-spacing-xl) * 1.5);\n  }\n  \n  .label {\n    font-family:\n      Greycliff CF,\n      var(--mantine-font-family);\n  }\n\n  /* .rs-stack-item  {\n    background-color: var(--bg-rs-item-card) !important;\n    color: var(--text-color);\n  } */\n\n  .account-card {\n    background-color: var(--bg-account-card) !important;\n  }\n\n  .m_1b7284a3 {\n    background-color: var(--bg-color) !important;\n    color: var(--text-color);\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
