// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.Updates{
    width: 85%;
    background: var(--bg-color) !important;
    border-radius: 0.7rem;
    padding: 1rem;
    gap: 1rem;
    display: flex;
    flex-direction: column;
    font-size: 13px;
}

.update{
    display: flex;
    gap: 0.5rem;
}

.update>img{
    width: 3.2rem;
    height: 3.2rem;
}

.noti>div>span:nth-of-type(1){
    font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/components/Dashboard/Notice/Notice.css"],"names":[],"mappings":";AACA;IACI,UAAU;IACV,sCAAsC;IACtC,qBAAqB;IACrB,aAAa;IACb,SAAS;IACT,aAAa;IACb,sBAAsB;IACtB,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,WAAW;AACf;;AAEA;IACI,aAAa;IACb,cAAc;AAClB;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":["\n.Updates{\n    width: 85%;\n    background: var(--bg-color) !important;\n    border-radius: 0.7rem;\n    padding: 1rem;\n    gap: 1rem;\n    display: flex;\n    flex-direction: column;\n    font-size: 13px;\n}\n\n.update{\n    display: flex;\n    gap: 0.5rem;\n}\n\n.update>img{\n    width: 3.2rem;\n    height: 3.2rem;\n}\n\n.noti>div>span:nth-of-type(1){\n    font-weight: bold;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
