import React from 'react';
import { rem } from '@mantine/core';

export const trendDown = ({ size, style, ...props }) => {
    return (
        <svg width="24"
        height="24"
        viewBox="0 0 24 24" fill="none" 
        style={{ width: rem(size), height: rem(size), ...style }} 
        {...props}
        xmlns="http://www.w3.org/2000/svg" 
        transform="rotate(270)" 
        stroke="#f24040">

        <g id="SVGRepo_bgCarrier" stroke-width="0"/>

        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>

        <g id="SVGRepo_iconCarrier"> <path d="M10 16.75C10.4142 16.75 10.75 16.4142 10.75 16C10.75 15.5858 10.4142 15.25 10 15.25V16.75ZM6 15.25C5.58579 15.25 5.25 15.5858 5.25 16C5.25 16.4142 5.58579 16.75 6 16.75V15.25ZM5.25 16C5.25 16.4142 5.58579 16.75 6 16.75C6.41421 16.75 6.75 16.4142 6.75 16H5.25ZM6.75 12C6.75 11.5858 6.41421 11.25 6 11.25C5.58579 11.25 5.25 11.5858 5.25 12H6.75ZM5.46967 15.4697C5.17678 15.7626 5.17678 16.2374 5.46967 16.5303C5.76256 16.8232 6.23744 16.8232 6.53033 16.5303L5.46967 15.4697ZM13 9L13.5303 8.46967C13.2374 8.17678 12.7626 8.17678 12.4697 8.46967L13 9ZM16 12L15.4697 12.5303C15.7626 12.8232 16.2374 12.8232 16.5303 12.5303L16 12ZM20.5303 8.53033C20.8232 8.23744 20.8232 7.76256 20.5303 7.46967C20.2374 7.17678 19.7626 7.17678 19.4697 7.46967L20.5303 8.53033ZM10 15.25H6V16.75H10V15.25ZM6.75 16V12H5.25V16H6.75ZM6.53033 16.5303L13.5303 9.53033L12.4697 8.46967L5.46967 15.4697L6.53033 16.5303ZM12.4697 9.53033L15.4697 12.5303L16.5303 11.4697L13.5303 8.46967L12.4697 9.53033ZM16.5303 12.5303L20.5303 8.53033L19.4697 7.46967L15.4697 11.4697L16.5303 12.5303Z" fill="#000000"/> </g>

        </svg>
    );
}