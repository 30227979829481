// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Footer CSS */

.footer{
    background-color: transparent;
}
.footer p{
    font-size: 14px;
}
.bg-grey {
    background-color: var(--bg-color);
    border-radius: 8px;
}

`, "",{"version":3,"sources":["webpack://./src/components/Dfooter.css"],"names":[],"mappings":"AAAA,eAAe;;AAEf;IACI,6BAA6B;AACjC;AACA;IACI,eAAe;AACnB;AACA;IACI,iCAAiC;IACjC,kBAAkB;AACtB","sourcesContent":["/* Footer CSS */\n\n.footer{\n    background-color: transparent;\n}\n.footer p{\n    font-size: 14px;\n}\n.bg-grey {\n    background-color: var(--bg-color);\n    border-radius: 8px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
