// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.MuiTableRow-root > * {
    padding: 10px;
    color: var(--text-color) !important;
  }
  
  .MuiTableRow-root.MuiTableRow-head > * {
    font-weight: bold !important;
  }

  .MuiPaper-root{
    background-color: var(--bg-color) !important;
  
  }
  
  .Table td,
  th {
    border: none !important;
  }

  th::after{
    border-bottom: var(--text-color) 1px !important;
  }
  
  .Table:first-child {
    border-radius: 0.7rem !important;
  }
  .status {
    padding: 8px;
    border-radius: 9px;
  }
  
  .Details {
    color: #00b5ff !important;
  }
  
  @media screen and (max-width: 1200px) {
    .Table {
      width: 170%;
      margin-top: 2rem;
    }
  }
  
  
  @media screen and (max-width: 768px) {
    .Table{
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 23rem;
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/Dashboard/Table/Table.css"],"names":[],"mappings":";;AAEA;IACI,aAAa;IACb,mCAAmC;EACrC;;EAEA;IACE,4BAA4B;EAC9B;;EAEA;IACE,4CAA4C;;EAE9C;;EAEA;;IAEE,uBAAuB;EACzB;;EAEA;IACE,+CAA+C;EACjD;;EAEA;IACE,gCAAgC;EAClC;EACA;IACE,YAAY;IACZ,kBAAkB;EACpB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE;MACE,WAAW;MACX,gBAAgB;IAClB;EACF;;;EAGA;IACE;MACE,kBAAkB;MAClB,aAAa;MACb,sBAAsB;MACtB,mBAAmB;MACnB,YAAY;IACd;EACF","sourcesContent":["\n\n.MuiTableRow-root > * {\n    padding: 10px;\n    color: var(--text-color) !important;\n  }\n  \n  .MuiTableRow-root.MuiTableRow-head > * {\n    font-weight: bold !important;\n  }\n\n  .MuiPaper-root{\n    background-color: var(--bg-color) !important;\n  \n  }\n  \n  .Table td,\n  th {\n    border: none !important;\n  }\n\n  th::after{\n    border-bottom: var(--text-color) 1px !important;\n  }\n  \n  .Table:first-child {\n    border-radius: 0.7rem !important;\n  }\n  .status {\n    padding: 8px;\n    border-radius: 9px;\n  }\n  \n  .Details {\n    color: #00b5ff !important;\n  }\n  \n  @media screen and (max-width: 1200px) {\n    .Table {\n      width: 170%;\n      margin-top: 2rem;\n    }\n  }\n  \n  \n  @media screen and (max-width: 768px) {\n    .Table{\n      position: relative;\n      display: flex;\n      flex-direction: column;\n      align-items: center;\n      width: 23rem;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
