// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#stack2{
    background-color:#e9ecef;
    margin: 0px!important;
    height: 100vh;
   }

.forgot-password-container {
    max-width: 500px;
    margin: 10% auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  
  .forgot-password-container h1 {
    margin-top: 0;
  }
  
  .forgot-password-container input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 3px;
  }
  
  .verification-method {
    margin-bottom: 10px;
  }
  
  .verification-method label {
    margin-right: 10px;
  }
  
  .forgot-password-container button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    border: none;
    border-radius: 3px;
    color: #fff;
    cursor: pointer;
  }
  
  .forgot-password-container button:hover {
    background-color: #0056b3;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/ForgotUP/Forgotun.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,qBAAqB;IACrB,aAAa;GACd;;AAEH;IACI,gBAAgB;IAChB,gBAAgB;IAChB,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,yBAAyB;EAC3B;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,WAAW;IACX,aAAa;IACb,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,WAAW;IACX,eAAe;EACjB;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":["#stack2{\n    background-color:#e9ecef;\n    margin: 0px!important;\n    height: 100vh;\n   }\n\n.forgot-password-container {\n    max-width: 500px;\n    margin: 10% auto;\n    padding: 20px;\n    border: 1px solid #ccc;\n    border-radius: 5px;\n    background-color: #f9f9f9;\n  }\n  \n  .forgot-password-container h1 {\n    margin-top: 0;\n  }\n  \n  .forgot-password-container input {\n    width: 100%;\n    padding: 10px;\n    margin-bottom: 10px;\n    border: 1px solid #ccc;\n    border-radius: 3px;\n  }\n  \n  .verification-method {\n    margin-bottom: 10px;\n  }\n  \n  .verification-method label {\n    margin-right: 10px;\n  }\n  \n  .forgot-password-container button {\n    width: 100%;\n    padding: 10px;\n    background-color: #007bff;\n    border: none;\n    border-radius: 3px;\n    color: #fff;\n    cursor: pointer;\n  }\n  \n  .forgot-password-container button:hover {\n    background-color: #0056b3;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
