// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Cards {
    display: flex;
    gap: 10px;
  }
  .parentContainer{
    width: 100%;
  }
  
  @media screen and (max-width: 1200px) {
    .Cards{
      flex-direction: column;
    }
    .parentContainer{
      height: 9rem;
    }
  }
  
  @media screen and (max-width: 768px)
  {
    .Cards{
      width: 90%;
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/Dashboard/Cards/Cards.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,SAAS;EACX;EACA;IACE,WAAW;EACb;;EAEA;IACE;MACE,sBAAsB;IACxB;IACA;MACE,YAAY;IACd;EACF;;EAEA;;IAEE;MACE,UAAU;IACZ;EACF","sourcesContent":[".Cards {\n    display: flex;\n    gap: 10px;\n  }\n  .parentContainer{\n    width: 100%;\n  }\n  \n  @media screen and (max-width: 1200px) {\n    .Cards{\n      flex-direction: column;\n    }\n    .parentContainer{\n      height: 9rem;\n    }\n  }\n  \n  @media screen and (max-width: 768px)\n  {\n    .Cards{\n      width: 90%;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
