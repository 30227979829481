// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.calculator-container {
  max-width: 400px;
  margin: auto;
  text-align: center;
}

.calculator-display {
  font-size: 3rem;
  text-align: right;
  padding: 10px;
  border: 2px solid #e0e0e0;
  border-radius: 10px;
  margin-bottom: 15px;
}

.calculator-buttons {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

.button-gray {
  background-color: #e0e0e0;
  color: black;
  border-radius: 50px;
  height: 60px;
  font-size: 1.2rem;
}

.button-orange {
  background-color: #ffa500;
  color: white;
  border-radius: 50px;
  height: 60px;
  font-size: 1.2rem;
}

.button-blue {
  background-color: #add8e6;
  color: white;
  border-radius: 20px;
  font-size: 1rem;
  padding: 10px 20px;
  margin-top: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Dashboard/PaymentCollect.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,aAAa;EACb,yBAAyB;EACzB,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,qCAAqC;EACrC,SAAS;AACX;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,mBAAmB;EACnB,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,mBAAmB;EACnB,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,mBAAmB;EACnB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;AAClB","sourcesContent":[".calculator-container {\n  max-width: 400px;\n  margin: auto;\n  text-align: center;\n}\n\n.calculator-display {\n  font-size: 3rem;\n  text-align: right;\n  padding: 10px;\n  border: 2px solid #e0e0e0;\n  border-radius: 10px;\n  margin-bottom: 15px;\n}\n\n.calculator-buttons {\n  display: grid;\n  grid-template-columns: repeat(4, 1fr);\n  gap: 10px;\n}\n\n.button-gray {\n  background-color: #e0e0e0;\n  color: black;\n  border-radius: 50px;\n  height: 60px;\n  font-size: 1.2rem;\n}\n\n.button-orange {\n  background-color: #ffa500;\n  color: white;\n  border-radius: 50px;\n  height: 60px;\n  font-size: 1.2rem;\n}\n\n.button-blue {\n  background-color: #add8e6;\n  color: white;\n  border-radius: 20px;\n  font-size: 1rem;\n  padding: 10px 20px;\n  margin-top: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
